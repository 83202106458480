"use client";
import Link from "next/link";
import "../../../contents/scss/footer.scss";
import { GetDefaultImagePath } from "../defaultImage/DefaultImage";
import { FacebookPath, InstaPath, LogoPath } from "../defaultImage/DefaultPaths";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect, useContext } from "react";
import AppContext from "@/StateManagement/AppContext";
import { SanityImage } from "@/sanity/SanityImage";
import CheckLink from "../../../utils/constants/LinkCheck";
import { appConfig } from "@/appConfig";

const Footer = (props) => {
    const { registeredRoute, filterApplied, setFilterApplied, setSearchBarText, setLinkAllCars, setSelectedMake } =
        useContext(AppContext);
    const router = useRouter();

    const handleLink = (e) => {
        if (e != null && e?.target?.href?.includes("#")) {
            e?.preventDefault();
            return;
        }
    };

    const handleClick = (make) => {
        if (make) {
            const filteredMake = props.facets.make.filter((item) => item.value === make);
            if (filteredMake) {
                const makeFilter = [
                    {
                        label: `${filteredMake[0].value} (${filteredMake[0].count})`,
                        value: filteredMake[0].value
                    }
                ];
                setSelectedMake(makeFilter);
                if (!filterApplied) {
                    setFilterApplied(true);
                }
            }

            setSearchBarText("");
            setLinkAllCars("");
        }
        router.push("/cars", { scroll: true });
    };

    return (
        <>
            {registeredRoute && (
                <div className="container-fluid footer_bg py-4">
                    <div className="container-fluid p-lg-5 p-sm-0">
                        <div className="row pt-4 ">
                            <div className="col-12">
                                <Link
                                    className="d-flex"
                                    onClick={handleLink}
                                    aria-label="Footer Logo"
                                    href={CheckLink(props.headerData?.link5?.link)}
                                    passHref>
                                    <div key="link5">
                                        <SanityImage
                                            src={props.headerData.link5.image}
                                            defaultImage={GetDefaultImagePath(LogoPath)}
                                            alt="THIScar.com"
                                            className="footer-logo-image w-auto h-auto"
                                            width={137}
                                            height={51}
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6 col-sm-6 col-md-6 container-custom admin-sign-in-footer">
                                <div className="row">
                                    <div className="col-sm-6 col-5">
                                        <ul className="p-0">
                                            {props.headerData?.link1?.linkText != null &&
                                                props.headerData?.link1?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.headerData?.link1?.link)}
                                                            passHref>
                                                            <React.Fragment key="link1">
                                                                {props.headerData?.link1?.linkText}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                            {props.headerData?.link2?.linkText != null &&
                                                props.headerData?.link2?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.headerData?.link2?.link)}
                                                            passHref>
                                                            <React.Fragment key="link2">
                                                                {props.headerData?.link2?.linkText}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                            {props.headerData?.link3?.linkText != null &&
                                                props.headerData?.link3?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.headerData?.link3?.link)}
                                                            passHref>
                                                            <React.Fragment key="link3">
                                                                {props.headerData?.link3?.linkText}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                            {props.headerData?.link4?.link4?.linkText != null &&
                                                props.headerData?.link4?.link4?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.headerData?.link4?.link4?.link)}
                                                            passHref>
                                                            <React.Fragment key="link4">
                                                                {props.headerData?.link4?.link4?.linkText}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                            {props.footerData?.link5?.linkText != null &&
                                                props.footerData?.link5?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.footerData?.link5?.link)}
                                                            passHref>
                                                            <React.Fragment key="link5">
                                                                {" "}
                                                                {props.footerData?.link5?.linkText}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                            {props.footerData?.link6?.linkText != null &&
                                                props.footerData?.link6?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.footerData?.link6?.link)}
                                                            passHref>
                                                            <React.Fragment key="link6">
                                                                {" "}
                                                                {props.footerData?.link6?.linkText}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                        </ul>
                                    </div>
                                    <div className="col-5">
                                        <ul className="p-0">
                                            {props.footerData?.link1?.linkText != null &&
                                                props.footerData?.link1?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.footerData?.link1?.link)}
                                                            passHref>
                                                            <React.Fragment key="link1">
                                                                {" "}
                                                                {props.footerData?.link1?.linkText}{" "}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                            {props.footerData?.link2?.linkText != null &&
                                                props.footerData?.link2?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.footerData?.link2?.link)}
                                                            passHref>
                                                            <React.Fragment key="link2">
                                                                {" "}
                                                                {props.footerData?.link2?.linkText}{" "}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                            {props.footerData?.link3?.linkText != null &&
                                                props.footerData?.link3?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.footerData?.link3?.link)}
                                                            passHref>
                                                            <React.Fragment key="link3">
                                                                {" "}
                                                                {props.footerData?.link3?.linkText}{" "}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                            {props.footerData?.link4?.linkText != null &&
                                                props.footerData?.link4?.linkText?.trim() != "" && (
                                                    <li>
                                                        <Link
                                                            onClick={handleLink}
                                                            href={CheckLink(props.footerData?.link4?.link)}
                                                            passHref>
                                                            <React.Fragment key="link4">
                                                                {" "}
                                                                {props.footerData?.link4?.linkText}{" "}
                                                            </React.Fragment>
                                                        </Link>
                                                    </li>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                                <ul className="row">
                                    {props.facets?.make
                                        .filter((x) => x.value !== "Fisker")
                                        .map((facet) => (
                                            <li
                                                key={facet.value}
                                                className="col-6">
                                                {/* <Link
                                                    prefetch={false}
                                                    href={`/cars?make=${facet.value}`}>
                                                    Find a {facet.value}
                                                </Link> */}
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    prefetch="false"
                                                    onClick={() => handleClick(facet.value)}>
                                                    Find a {facet.value}
                                                </span>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <div
                                className="col-sm-6"
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "flex-start"
                                }}>
                                <div className="col-lg-3 mt-3 mt-lg-0 tab-display btm-right-in mb-3">
                                    <div className="row">
                                        <div className="col-lg-12 d-flex tab-right">
                                            {/* <span>login</span> */}
                                            <span>
                                                {props.footerData.facebookLink?.linkText != null && (
                                                    <Link
                                                        onClick={handleLink}
                                                        href={CheckLink(props.footerData.facebookLink?.link)}
                                                        passHref>
                                                        <SanityImage
                                                            key="facebookLink"
                                                            src={props.footerData.facebookLink.image}
                                                            defaultImage={GetDefaultImagePath(FacebookPath)}
                                                            alt="facebook"
                                                            width={45}
                                                            height={45}
                                                            className="footer-social-media-image-tab"
                                                        />
                                                    </Link>
                                                )}
                                            </span>
                                            <span>
                                                {props.footerData.instagramLink?.linkText != null && (
                                                    <Link
                                                        onClick={handleLink}
                                                        href={CheckLink(props.footerData.instagramLink?.link)}
                                                        passHref>
                                                        <SanityImage
                                                            key="instagramLink"
                                                            // src={urlFor(
                                                            //   props.footerData.instagramLink?.image
                                                            // ).url()}
                                                            src={props.footerData.instagramLink.image}
                                                            defaultImage={GetDefaultImagePath(InstaPath)}
                                                            alt="instagram"
                                                            width={45}
                                                            height={45}
                                                            className="footer-social-media-image-tab"
                                                        />
                                                    </Link>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="text-center">
                                    <span className="copy_right">
                                        &copy; {props.footerData.copyRightsText1}&trade;
                                        {props.footerData.copyRightsText2}{" "}
                                        {props.footerData.termsConditions?.linkText != null &&
                                            props.footerData.termsConditions?.linkText?.trim() != "" && (
                                                <Link
                                                    onClick={handleLink}
                                                    href={CheckLink(props.footerData.termsConditions?.link)}
                                                    passHref>
                                                    <React.Fragment key="instagramLink">
                                                        {" "}
                                                        {props.footerData.termsConditions?.linkText}
                                                    </React.Fragment>
                                                </Link>
                                            )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Footer;
